<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-header">
      <el-select
        class="select-primary pagination-select"
        v-model="pagination.perPage"
        :placeholder="$t('COMMON.PER_PAGE')"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <reseller-selector
        v-if="
          !filterReseller &&
          $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
        "
        @resellerChanged="(resellerId) => (selectedReseller = resellerId)"
      />

      <div>
        <base-input
          v-model="query"
          type="search"
          prepend-icon="fas fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />
      </div>
    </div>
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="packs"
          @sort-change="sortChange"
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" />
          </div>
          <el-table-column
            :label="$t('COMMON.CODE')"
            prop="code"
            sortable="custom"
            min-width="220"
          />
          <el-table-column
            :label="$t('COMMON.NAME')"
            prop="name"
            sortable="custom"
            min-width="220"
          />
          <el-table-column
            :label="$t('COMMON.PRICE')"
            prop="price"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>
                {{ $formatCurrency(row.price) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.RESELLER')"
            sortable="custom"
            min-width="220"
            v-if="
              !filterReseller &&
              $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
            "
          >
            <template v-slot="{ row }">
              <reseller :reseller="row.reseller" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('PACKAGES.DEFAULT_ROLE')"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>
                {{ row.default_role ? row.default_role.name : null }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.CREATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          />
          <el-table-column fixed="right" min-width="120">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_PACKAGES)"
              >
                <a
                  type="text"
                  @click="viewPack(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-expand-alt"></i>
                </a>
              </el-tooltip>

              <el-tooltip
                :content="$t('COMMON.EDIT')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_EDIT_PACKAGES)"
              >
                <a
                  type="text"
                  @click="editPack(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-edit"></i>
                </a>
              </el-tooltip>

              <el-tooltip
                content="Delete"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_DELETE_PACKAGES)"
              >
                <a
                  type="text"
                  @click="deletePack(row)"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-trash-alt"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}
        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { BasePagination } from "@/components";
import ResellerSelector from "@/components/ResellerSelector.vue";

export default {
  name: "package-list-table",

  components: {
    BasePagination,
    ResellerSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    filterReseller: {
      type: String,
      default: null,
      description: "reseller id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      packs: [],
      loading: true,
      selectedReseller: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "reseller,default_role",
        };

        if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }
        if (this.selectedReseller) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: this.selectedReseller,
            },
          };
        }

        await this.$store.dispatch("packages/list", params);
        this.packs = this.$store.getters["packages/list"];
        this.total = this.$store.getters["packages/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deletePack(pack) {
      this.$emit("onDeletePackage", pack);
    },

    viewPack(pack) {
      this.$emit("onViewPackage", pack);
    },

    editPack(pack) {
      this.$emit("onEditPackage", pack);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
