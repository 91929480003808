<template>
  <div>
    <div
      v-for="model in models"
      :key="model"
      class="col-12 permissions-wrapper"
    >
      <h4 class="form-control-label">
        {{ $t(`COMMON.${model}`) }}
      </h4>
      <div class="permissions-wrapper-inner">
        <div
          class="permissions-wrapper-inner-item"
          v-for="permission in permissions.filter((perm) =>
            perm.key.includes(model)
          )"
          :key="permission.key"
        >
          <icon-check :checked="hasPermission(permission)" />
          {{ $t(`PERMISSIONS.${permission.key}`) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconCheck from "@/components/IconCheck.vue";
import models from "@/constants/models";

export default {
  name: "role-permissions",

  components: { IconCheck },

  props: ["role"],

  data() {
    return { models: models, permissions: [] };
  },

  computed: {},

  created() {
    this.getPermissions();
  },

  methods: {
    hasPermission(permission) {
      if (!this.role.permissions) {
        return false;
      }
      return !!this.role.permissions.find((perm) => {
        return perm.id === permission.id;
      });
    },
    async getPermissions() {
      try {
        await this.$store.dispatch("permissions/list", {
          sort: "name",
          page: {
            number: 1,
            size: 999999,
          },
        });
        this.permissions = await this.$store.getters["permissions/list"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },

  mounted() {},

  watch: {
    role(role) {},
  },
};
</script>
