export default {
  type: "packages",
  name: null,
  excerpt: "",
  price: null,
  taxes: [],
  picture: null,
  gallery: [],
  frequency: null,
  relationshipNames: ["reseller", "default_role"],
  reseller: {
    type: "resellers",
    id: null,
  },
  default_role: {
    type: "roles",
    id: null,
  },
  maximum_users: 1,
};
